const AAVE_ETH_CHAIN_COINLIST = {
  WETH: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  WBTC: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
  USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  // WSTETH: "0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0",
  // LINK: "0x514910771af9ca656af840dff83e8264ecf986ca",
  // RETH: "0xae78736cd615f374d3085123a210448e74fc6393",
  // USDT: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  // CBETH: "0xbe9895146f7af43049ca1c1ae358b0541ea49704",
  // MKR: "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
  // UNI: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
  // DAI: "0x6b175474e89094c44da98b954eedeac495271d0f",
  // RPL: "0xd33526068d116ce69f19a9ee46f0bd304f21a51f",
  // SNX: "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f",
  // STG: "0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6",
  // LUSD: "0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
  // BAL: "0xba100000625a3754423978a60c9317c58a424e3D",
  // CRV: "0xD533a949740bb3306d119CC777fa900bA034cd52",
  // "1INCH": "0x111111111117dc0aa78b770fa6a738034120c302",
  // ENS: "0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72",
  // FRAX: "0x853d955acef822db058eb8505911ed77f175b99e",
  // FXS: "0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0",
  // KNC: "0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0",
  // CRVUSD: "0xf939e0a03fb07f59a73314e73794be0e57ac1b4e",
};

export { AAVE_ETH_CHAIN_COINLIST };
